/** @jsxImportSource theme-ui */
import React from "react";
import Header from "./components/_shared-components/Header";
import Router from "./router/Router";
import NewsLetter from "./components/_shared-components/NewsLetter";
import Footer from "./components/_shared-components/Footer";


const Layout: React.FC = () => {
  return (
    <div sx={{
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      fontFamily: 'body',
      flex: 1,
      minHeight: '100vh'
    }}>
      <Header />
      <Router/>
      <NewsLetter />
      <Footer />
    </div>
  );
};

export default Layout;
