/** @jsxImportSource theme-ui */
import React from "react";
import {Container, Flex} from 'theme-ui'
import {IProduct} from "../../rest/products";
import {useHistory} from "react-router";


interface IProductCardProps {
  product: IProduct;
}

const ProductCard: React.FC<IProductCardProps> = ({product}) => {
  const history = useHistory();
  return (
    <Flex sx={{
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      height: 120,
      width: 353,
      position: "relative",
      background: (t) => t?.colors?.gradientColor,
      borderRadius: 10,
      padding: '20px',
      mr: '16px',
      '&:last-child': {
        mr: 0
      }
    }}>
      <Flex sx={{
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "start",
        fontSize: 18,
        color: "white",
        fontWeight: "bold",
      }}>
        <span>{product.name}</span>
        <img src="/images/link.png" alt="link" sx={{
          width: '30px',
          marginTop: '21px',
          cursor: "pointer"
        }}
          onClick={() => {
            history.push("/products/" + product.id);
          }}
        />
      </Flex>
      <Flex sx={{
        justifyContent: "center",
        alignItems: "center",
      }}>
        <img src={product.image} alt="product" sx={{
          position: 'relative',
          width: product.productCardPictureWidth + 'px',
          bottom: product.productCardPictureBottom + 'px'
        }}/>
      </Flex>
    </Flex>
  );
};

const ProductDetailsPageOtherProductsSection: React.FC<{ similarProducts: IProduct[] }> = (props: { similarProducts: IProduct[] }) => {
  return (
    <div sx={{
      bg: 'grayBackground',
    }}>
      <Container sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(12, [col] 1fr)',
        gridColumnGap: '16px',
        paddingTop: '53px',
        paddingBottom: '100px'
      }}>
        <Flex sx={{
          gridColumn: 'col 2 / span 10',
          gridRow: 'row',
          flexDirection: 'column'
        }}>
          <h2 sx={{fontSize: "24px", fontWeight: "bold", marginBottom: 20}}>
            {"Weitere Produkte"}
          </h2>
          <Flex sx={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}>
            {props.similarProducts.map((record) =>
              <ProductCard key={record.id} product={record}/>
            )}
          </Flex>
        </Flex>
      </Container>
    </div>
  );
};

export default ProductDetailsPageOtherProductsSection;
