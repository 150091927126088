/** @jsxImportSource theme-ui */
import React from "react";
import {Container, Flex, Box} from 'theme-ui'


type homePageServicesSectionItemsType = {
  title: string,
  content: string,
  top: number
}
const homePageServicesSectionItems: homePageServicesSectionItemsType[] = [
  {
    title: 'Input',
    content: 'Ihre Zufriedenheit und Ihre Zeit sind uns wichtig. Deshalb bieten wir Ihnen ein nahtloses digitales Erlebnis an. ' +
      'Wir sammeln Informationen über Ihr Haus, um die beste Lösung für Ihre Bedürfnisse zu ermitteln.',
    top: 0
  },
  {
    title: 'Ergebnisse',
    content: 'Unser E-Consultant erstellt in wenigen Sekunden eine maßgeschneiderte Produktempfehlung. ' +
      'Zusätzlich erhalten Sie eine Kostenschätzung zusammen mit den voraussichtlichen Einsparungen.',
    top: 93
  },
  {
    title: 'Installationsservices',
    content: 'Unsere Installateure beantworten Ihre Fragen zur Produktempfehlung. ' +
      'Über unsere Plattform vereinbaren sie mit Ihnen einen Termin zur Sichtung Ihres Hauses und zur Installation.',
    top: 0
  },
  {
    title: 'Übergabe',
    content: 'Unsere Installateure sorgen für die Ordnungsgemäße Installation und Konfiguration der Systeme. ' +
      'Sie übergeben Ihnen eine funktionsfähige Anlage und führen Sie in die Bedienung ein.',
    top: 93
  }
];

const HomePageServicesSection: React.FC = () => {
  const [servicesMode] = React.useState<string>(homePageServicesSectionItems[2].title);
  return (
    <div sx={{
      background: 'url(/home-page/home-page-services-section-bg.png) no-repeat bottom',
      paddingTop: '80px',
      paddingBottom: '154px'
    }}>
      <Container sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <h2 sx={{
          fontSize: 6,
          fontWeight: 'bold',
          marginBottom: '22px'
        }}>{"Unsere Dienstleistungen"}</h2>
        <p sx={{
          maxWidth: '540px',
          lineHeight: 'text',
          mb: '58px',
          textAlign: 'center'
        }}>{"Unser Ziel ist es, Ihnen eine ganzheitliche Energielösung für Ihr Haus anzubieten. " +
        "Unsere Dienstleistungen und Teams sind darauf ausgerichtet, alle Ihre Anforderungen und Bedürfnisse zu erfüllen."}</p>
        <Flex sx={{
          flexDirection: 'row'
        }}>
          {homePageServicesSectionItems.map((singleItem: homePageServicesSectionItemsType, index: number) =>
            <Box key={index} sx={{
              position: 'relative',
              top: singleItem.top + 'px',
              width: '274px',
              height: '348px',
              px: '17px',
              py: '38px',
              borderRadius: '10px',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
              background: (t) => (servicesMode === singleItem.title ? t?.colors?.crookedGradientColor : 'white'),
              color: (servicesMode === singleItem.title ? 'white' : 'text'),
              marginRight: '12px',
              '&:last-child': {
                mr: 0
              }
            }}>
              <h3 sx={{
                fontSize: '21px',
                fontWeight: 'bold',
                marginBottom: '17px'
              }}>{singleItem.title}</h3>
              <p sx={{
                lineHeight: 'text'
              }}>{singleItem.content}</p>
              <span sx={{
                position: 'absolute',
                bottom: '-10px',
                right: '17px',
                fontSize: '38px',
                fontWeight: 'bold',
                color: (servicesMode === singleItem.title ? 'white' : 'primary')
              }}>{index+1}</span>
            </Box>
          )}
        </Flex>
      </Container>
    </div>
  );
};

export default HomePageServicesSection;
