/** @jsxImportSource theme-ui */
import React from "react";
import {Container, Flex} from 'theme-ui'
import EnergyGeneration from "../../assets/icons/home-page-system-section-icons/EnergyGeneration";
import SustainableHeating from "../../assets/icons/home-page-system-section-icons/SustainableHeating";
import VehicleCharging from "../../assets/icons/home-page-system-section-icons/VehicleCharging";
import EnergyStorage from "../../assets/icons/home-page-system-section-icons/EnergyStorage";
import EnergyManagement from "../../assets/icons/home-page-system-section-icons/EnergyManagement";
import ArrowRight from "../../assets/icons/ArrowRight";
import {useHistory} from "react-router-dom";


type homePageSystemSectionItemsType = {
  key: string,
  icon: React.ElementType,
  content: string,
  to: string,
  top: number,
  left: number,
  sideLeft: number,
  sideBottom: number,
  stroke?: string
}
const homePageSystemSectionItems: homePageSystemSectionItemsType[] = [
  {
    key: 'energy-generation',
    icon: EnergyGeneration,
    content: 'Energie-erzeugung',
    to: '/products/solar-inverter',
    top: -22,
    left: 21,
    sideLeft: -40,
    sideBottom: -35
  },
  {
    key: 'sustainable-heating',
    icon: SustainableHeating,
    content: 'Nachhaltiges Heizen',
    to: '/products/heat-pump',
    top: -12,
    left: 260,
    sideLeft: -140,
    sideBottom: 0
  },
  {
    key: 'vehicle-charging',
    icon: VehicleCharging,
    content: 'E-Fahrzeug laden',
    to: '/products/wallbox',
    top: -37,
    left: 486,
    sideLeft: -20,
    sideBottom: 40,
    stroke: '#2c83cb'
  },
  {
    key: 'energy-storage',
    icon: EnergyStorage,
    content: 'Energie speichern',
    to: '/products/energy-storage-system',
    top: 137,
    left: 131,
    sideLeft: 0,
    sideBottom: -15,
    stroke: '#317eca'
  },
  {
    key: 'energy-management',
    icon: EnergyManagement,
    content: 'Energy Management System',
    to: '',
    top: 137,
    left: 337,
    sideLeft: -100,
    sideBottom: -10
  },
];
const HomePageSystemSection: React.FC = () => {
  const history = useHistory();
  const [solutionMode, setSolutionMode] = React.useState<homePageSystemSectionItemsType>(homePageSystemSectionItems[0]);
  return (
    <div sx={{
      bg: 'rgba(244, 244, 244, 0.56)',
      overflow: 'hidden'
    }}>
      <Container sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(12, [col] 1fr)',
        gridColumnGap: '16px',
        paddingTop: '75px',
        paddingBottom: '95px'
      }}>
        <Flex sx={{
          gridColumn: 'col 2 / span 11',
          gridRow: 'row',
          flexDirection: 'row'
        }}>
          <Flex sx={{
            flex: 1,
            flexDirection: 'column'
          }}>
            <h2 sx={{
              fontSize: 6,
              fontWeight: 'bold',
              marginBottom: '20px'
            }}>{"Vernetzte Energielösungen"}</h2>
            <p sx={{
              maxWidth: '425px',
              lineHeight: 'text',
              mb: '55px'
            }}>{"Unsere Energielösungssysteme für Privathaushalte sind hochmoderne " +
            "Technologien mit bewährten Leistungsstandards."}</p>
            <Flex sx={{
              width: '610px',
              height: '272px',
              position: 'relative',
              background: 'url(/home-page/home-page-system-section-bg.png)'
            }}>
              {homePageSystemSectionItems.map((singleItem: homePageSystemSectionItemsType, index: number) =>
                <Flex key={index} sx={{
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  width: '140px',
                  height: '140px',
                  padding: '0 10px',
                  borderRadius: '50%',
                  position: 'absolute',
                  top: singleItem.top + 'px',
                  left: singleItem.left + 'px',
                  boxShadow: '0 0 6px rgba(0, 0, 0, 0.1)',
                  color: (solutionMode.key === singleItem.key ? 'white' : 'secondary'),
                  bg: 'white',
                  cursor: 'pointer',
                  zIndex: 1,
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    borderRadius: '50%',
                    width: '100%',
                    height: '100%',
                    background: (t) => t?.colors?.crookedGradientColor,
                    opacity: (solutionMode.key === singleItem.key ? '1' : '0'),
                    zIndex: -1,
                    transition: 'all 0.3s ease',
                  }
                }} onMouseEnter={() => setSolutionMode(singleItem)}>
                  <singleItem.icon sx={{
                    fill: (solutionMode.key === singleItem.key ? 'white' : '#169cd3'),
                    stroke: ((solutionMode.key === singleItem.key && singleItem.stroke) ? singleItem.stroke : '')
                  }}/>
                  <div sx={{
                    mt: '10px',
                    fontWeight: 'heading'
                  }}>{singleItem.content}</div>
                </Flex>
              )}
            </Flex>
          </Flex>
          <Flex sx={{
            position: 'relative',
            right: '-47px',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '610px',
            height: '610px',
            marginLeft: '48px',
            background: 'url(/home-page/home-page-system-section-' + solutionMode.key + '-pic.png) no-repeat',
            backgroundSize: 'contain'
          }}>
            <Flex sx={{
              position: 'absolute',
              bottom: '190px',
              left: '200px',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}>
              <div sx={{
                maxWidth: '325px',
                fontSize: 5,
                fontWeight: 'bold',
                color: 'white',
                mb: '20px'
              }}>
                {solutionMode.content}
              </div>
              <button onClick={() => history.push(solutionMode.to)} sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                variant: 'buttons.colorful',
                background: (t) => t?.colors?.gradientColor,
                fontFamily: 'body',
                lineHeight: 'body',
                fontWeight: 'bold',
                fontSize: 2
              }}>
              <span sx={{
                marginRight: '7px'
              }}>
                {"Mehr Infos"}
              </span>
                <ArrowRight/>
              </button>
            </Flex>
            <img sx={{
              position: 'absolute',
              left: solutionMode.sideLeft,
              bottom: solutionMode.sideBottom,
              objectFit: 'contain',
            }} src={"/home-page/home-page-system-section-" + solutionMode.key + "-side-pic.png"} alt={solutionMode.key}/>
          </Flex>
        </Flex>
      </Container>
    </div>
  );
};

export default HomePageSystemSection;
