const theme = {
  fonts: {
    body: '"Montserrat", sans-serif',
    heading: 'inherit',
    monospace: 'inherit',
  },
  fontSizes: [
    12, 14, 15, 16, 20, 24, 34, 42, 48, 54, 64
  ],
  fontWeights: {
    body: 400,
    heading: 500,
    bold: 700,
  },
  colors: {
    text: '#212121',
    background: '#fff',
    primary: '#169CD3',
    secondary: '#137198',
    purple: '#5F49BA',
    white: 'white',
    grayBackground: '#F4F4F4',
    glassyBackground: 'rgba(255, 255, 255, 0.1)',
    gradientColor: 'linear-gradient(90deg, rgba(22,156,211,1) 0%, rgba(19,113,152,1) 50%, rgba(95,73,186,1) 100%)',
    crookedGradientColor: 'linear-gradient(135deg, rgba(22,156,211,1) 0%, rgba(95,73,186,1) 100%)'
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  sizes: {
    container: 1316,
  },
  lineHeights: {
    body: 1.125,
    text: '30px',
    heading1: 1.125,
    heading2: 1.125,
  },
  text: {
    heading1: {
      fontFamily: 'heading',
      lineHeight: 'heading1',
      fontWeight: 'bold',
    },
    heading2: {
      fontFamily: 'heading',
      lineHeight: 'heading2',
      fontWeight: 'body',
    }
  },
  styles: {
    root: {
      fontSize: 15
    },
    h1: {
      variant: 'text.heading1',
      fontSize: 9,
    }
  },
  buttons: {
    colorful: {
      color: 'white',
      height: '50px',
      borderRadius: '10px',
      border: 'none',
      paddingY: '16px',
      paddingX: '20px',
    },
    secondary: {
      color: 'text',
      bg: 'secondary',
    },
  }
};

export default theme