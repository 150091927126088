/** @jsxImportSource theme-ui */
import React from 'react'
import {ThemeUIStyleObject} from '@theme-ui/css'


const NightIcon = (props: { sx?: ThemeUIStyleObject }) =>
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="25.58" height="25.545" viewBox="0 0 25.58 25.545" fill="#434343">
    <g id="xb2a2h.tif" transform="matrix(0.883, 0.469, -0.469, 0.883, -2.617, -659.188)">
      <g id="Group_141" data-name="Group 141" transform="translate(319.598 576.643)">
        <path id="Path_154" data-name="Path 154"
              d="M206.6,585.363c.05-.377.085-.757.152-1.131a9.323,9.323,0,0,1,1.858-4.173,9.545,9.545,0,0,1,4.927-3.351,1.054,1.054,0,0,1,.485-.052.617.617,0,0,1,.351.989,14.264,14.264,0,0,0-.845,1.339,6.643,6.643,0,0,0-.684,2.679,7.1,7.1,0,0,0,3.234,6.378,6.839,6.839,0,0,0,2.537,1.013,7,7,0,0,0,5.819-1.487.8.8,0,0,1,.536-.211.565.565,0,0,1,.558.705,9.764,9.764,0,0,1-1.365,3.169,9.619,9.619,0,0,1-6.83,4.2,9.209,9.209,0,0,1-5.282-.865A9.434,9.434,0,0,1,206.829,588c-.1-.423-.131-.862-.2-1.294-.008-.054-.024-.107-.036-.161Zm5.739-6.8a8.307,8.307,0,0,0,3.309,15.63,8.39,8.39,0,0,0,8.054-4.578,8.423,8.423,0,0,1-11.363-11.052Z"
              transform="translate(-206.598 -576.643)"/>
      </g>
    </g>
  </svg>
;

export default NightIcon