/** @jsxImportSource theme-ui */
import React, {useState} from 'react'
import {useLocation} from "react-router";
import {Container, Flex} from "theme-ui";
import {Link} from "react-router-dom";
import Logo from "../../assets/icons/Logo";
import ChevronDown from "../../assets/icons/ChevronDown";


type HeaderLinksType = {
  title: string,
  to: string,
  items?: HeaderLinksType[]
}
const HeaderLinks: HeaderLinksType[] = [
  {
    title: 'Home',
    to: '/home'
  },
  {
    title: 'Produkte',
    to: '/products',
    items: [
      {
        title: 'Wechselrichter',
        to: '/solar-inverter'
      },
      {
        title: 'Wärmepumpe',
        to: '/heat-pump'
      },
      {
        title: 'Wallbox',
        to: '/wallbox'
      },
      {
        title: 'Batteriespeicher',
        to: '/energy-storage-system'
      }
    ]
  },
  {
    title: 'Kontakt',
    to: '/contact-us'
  },
  {
    title: 'Support',
    to: '/support'
  },
];

const Header = () => {
  const location = useLocation();
  const [hoveredItem, setHoveredItem] = useState<number | undefined>();
  const headerHeight: string = '81px';

  function setOnHoverHeaderItem(index?: number) {
    if (index) {
      setHoveredItem(index);
    }
    else {
      setHoveredItem(undefined);
    }
  }

  return (
    <Container px={4} mt={'25px'} sx={{
      position: 'absolute',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      alignSelf: 'center',
      justifyContent: 'space-between',
      height: headerHeight,
      borderRadius: '10px',
      zIndex: '100',
      '&::before': {
        content: '""',
        position: 'absolute',
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'glassyBackground',
        backdropFilter: 'blur(10px)',
        borderRadius: '10px',
        zIndex: -1
      }
    }} onMouseLeave={() => setOnHoverHeaderItem()}>
      <Logo/>
      <Flex as="nav" sx={{
        height: '100%',
        position: 'relative',
        marginRight: '28px'
      }}>
        {HeaderLinks.map((singleLink: HeaderLinksType, index: number) =>
          <div key={index}>
            <Link to={singleLink.to} sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'white',
              fontWeight: 'heading',
              height: '100%',
              px: '20px',
              borderTop: '4px solid transparent',
              borderColor: (location.pathname === singleLink.to ? 'white' : 'transparent'),
              transition: 'all 0.3s ease'
            }} onMouseEnter={() => setOnHoverHeaderItem(index)}>
              {singleLink.title}
              {singleLink.items?.length &&
              <ChevronDown sx={{
                display: 'flex',
                marginLeft: '7px'
              }}/>
              }
            </Link>
            {singleLink.items?.length &&
            <ul sx={{
              position: 'absolute',
              top: headerHeight,
              padding: 0,
              display: 'flex',
              flexDirection: 'column',
              borderBottomLeftRadius: '10px',
              borderBottomRightRadius: '10px',
              overflow: (hoveredItem === index ? 'auto' : 'hidden'),
              height: (hoveredItem === index ? '194px' : '0px'),
              transition: 'height 0.3s ease',
            }} onMouseEnter={() => setOnHoverHeaderItem(index)}>
              <div sx={{
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'glassyBackground',
                  backdropFilter: 'blur(10px)',
                  zIndex: -1
                }
              }}>
                {singleLink.items.map((singleItem: HeaderLinksType, subLinkIndex: number) =>
                  <Link key={subLinkIndex} to={singleLink.to + singleItem.to} sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: '48px',
                    paddingX: '24px',
                    borderBottom: '1px dashed rgba(255, 255, 255, 0.23)',
                    '&:last-child': {
                      borderBottom: 'unset',
                    },
                    color: (location.pathname === singleLink.to + singleItem.to ? 'black' : 'white'),
                    fontWeight: (location.pathname === singleLink.to + singleItem.to ? 'bold' : 'heading'),
                    transition: 'all 0.2s ease'
                  }}>
                    {singleItem.title}
                  </Link>
                )}
              </div>
            </ul>
            }
          </div>
        )}
      </Flex>
    </Container>
  )
};

export default Header