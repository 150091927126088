/** @jsxImportSource theme-ui */
import React from "react";
import {ThemeProvider} from 'theme-ui'
import theme from "src/theme";
import Layout from "./Layout";
import {BrowserRouter} from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import './assets/styles/global.scss'


const App = () => (
  <ThemeProvider theme={theme}>
    <React.StrictMode>
      <BrowserRouter>
        <ScrollToTop />
        <Layout/>
      </BrowserRouter>
    </React.StrictMode>
  </ThemeProvider>
);

export default App;
