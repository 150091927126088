/** @jsxImportSource theme-ui */
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {getProducts, IProduct} from "src/rest/products";
import {Box, Container, Flex} from "theme-ui";

const ProductsList: React.FC = () => {
  const history = useHistory();
  const [activeProduct, setActiveProduct] = useState<IProduct>();

  const [products, setProducts] = useState<IProduct[]>([]);

  useEffect(() => {
    getProducts().then((data) => {
      setProducts(data);
      setActiveProduct(data[0]);
    });
  }, []);
  return (
    <Flex sx={{
      alignSelf: "stretch",
      background: "#F4F4F4",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      pb: '115px'
    }}>
      <Container sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(12, [col] 1fr)',
        gridColumnGap: '16px',
        paddingTop: '75px',
        paddingBottom: '95px'
      }}>
        <Flex sx={{
          flexDirection: 'column',
          alignItems: 'center',
          gridColumn: 'col 2 / span 10',
          gridRow: 'row',
        }}>
          <h1 sx={{
            marginTop: 25,
            fontWeight: "bold",
            fontSize: "34px",
          }}
          >
            Produktübersicht
          </h1>
          <p sx={{
            marginTop: 25,
            maxWidth: 428,
            fontSize: 15,
            lineHeight: "30px",
            textAlign: "center",
            fontWeight: "normal",
          }}
          >
            Unsere Produktpalette bietet modernste Technologien. Mit garantierter
            Kompatibilität nutzen unsere Lösungen den Datenaustausch, um das Beste
            aus Ihrem Energiesystem herauszuholen.
          </p>
          <Flex sx={{
            flexDirection: "row",
            marginTop: 50,
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}>
            {products.map((product, index: number) => (
              <ProductCard key={index}
                           onClick={() => {
                             setActiveProduct(product);
                           }}
                           product={product}
                           active={activeProduct?.id === product.id}
              />
            ))}
          </Flex>
          {activeProduct && (
            <Flex sx={{
              flexDirection: "row",
              minHeight: 350,
              background: "white",
              width: "100%",
              maxWidth: 1094,
              borderRadius: 10,
              marginTop: 20,
              boxShadow: "0px 0px 10px 10px #eee",
              paddingTop: '43px',
              paddingBottom: '64px',
              paddingLeft: '35px',
              paddingRight: '50px',
            }}
            >
              <Flex
                sx={{flex: 1, justifyContent: "center", alignItems: "center"}}
              >
                <img
                  src={activeProduct.image}
                  alt="product"
                  style={{maxWidth: 290}}
                />
              </Flex>
              <Flex
                sx={{
                  flex: 2,
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "start",
                }}
              >
                <h2>{activeProduct.name}</h2>
                <h5
                  sx={{
                    marginTop: 25,
                    maxWidth: 539,
                    fontSize: 15,
                    minHeight: 180,
                    lineHeight: "24px",
                    textAlign: "start",
                    fontWeight: "normal",
                  }}
                >
                  {activeProduct.description}
                </h5>
                <button
                  onClick={() => {
                    history.push("/products/" + activeProduct.id);
                  }}
                  sx={{
                    marginTop: 20,
                    color: "white",
                    cursor: "pointer",
                    borderRadius: 10,
                    padding: 10,
                    minWidth: 120,
                    background:
                      "linear-gradient(90deg, #169CD3 0%, #137198 50%, #5F49BA 100%)",
                    borderWidth: 0,
                  }}
                >
                  Mehr Infos
                </button>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Container>
    </Flex>
  );
};

interface IProductCardProps {
  active: boolean;
  onClick: () => void;
  product: IProduct;
}

const ProductCard: React.FC<IProductCardProps> = ({active, onClick, product}) => {
  return (
    <Flex
      onClick={onClick}
      sx={{
        height: 86,
        width: 258,
        background: (t) => (active ? t?.colors?.crookedGradientColor : 'white'),
        flex: 1,
        cursor: "pointer",
        borderRadius: 10,
        flexDirection: "row",
        justifyContent: "space-between",
        paddingY: 10,
        paddingLeft: 20,
        marginRight: 20,
        '&:last-child': {
          ml: 0
        }
      }}
    >
      <Box sx={{
        fontSize: "18px",
        color: active ? "white" : "#777777",
        fontWeight: "bold",
      }}>
        {product.name}
      </Box>
      <img src={product.image} alt="product" sx={{
        position: 'relative',
        objectFit: 'contain',
        width: product.imageSize.w,
        height: product.imageSize.h,
        marginRight: product.imageSize.mr,
        bottom: product.imageSize.mb,
      }}/>
    </Flex>
  );
};

export default ProductsList;
