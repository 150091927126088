/** @jsxImportSource theme-ui */
import React from 'react'
import {Container, Box} from "theme-ui";
import {Link} from "react-router-dom";
import FooterLogo from "../../assets/icons/FooterLogo";


type FooterLinksType = {
  title: string,
  to: string
}
const FooterLinks: FooterLinksType[] = [
  {
    title: 'Kontakt',
    to: '/contact-us'
  },
  {
    title: 'Menü',
    to: '/menu'
  },
  {
    title: 'Subscribe',
    to: '/subscribe'
  },
];

const Footer = () => {
  return (
    <Box sx={{
      bg: 'black',
      height: '500px',
      paddingY: '78px'
    }}>
      <Container sx={{
        display: 'flex',
        flexDirection: 'row'
      }}>
        <Box sx={{
          flex: 1
        }}>
        <FooterLogo/>
        </Box>
        {FooterLinks.map((singleItem: FooterLinksType, index: number) =>
          <Box key={index} sx={{
            flex: 1
          }}>
            <Link to={singleItem.to} sx={{
              fontSize: '18px',
              color: '#C6C6C6'
            }}>{singleItem.title}</Link>
          </Box>
        )}
      </Container>
    </Box>
  )
};

export default Footer