/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getProducts, getSimilarProducts, IProduct } from "src/rest/products";
import {Redirect} from 'react-router-dom'
import ProductDetailsPageHeader from "../components/product-details-page/ProductDetailsPageHeader";
import ProductDetailsPageDescriptionSection
  from "../components/product-details-page/ProductDetailsPageDescriptionSection";
import ProductDetailsPageTechnicalSection from "../components/product-details-page/ProductDetailsPageTechnicalSection";
import ProductDetailsPageOtherProductsSection
  from "../components/product-details-page/ProductDetailsPageOtherProductsSection";
// import ProductDetailsPagePhotoSection from "../components/product-details-page/ProductDetailsPagePhotoSection";

const ProductDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [product, setProduct] = useState<IProduct>();
  const [similarProducts, setSimilarProducts] = useState<IProduct[]>([]);
  useEffect(() => {
    getProducts().then((data) => {
      const result = data.find((rec) => rec.id === id);
      if (result) {
        setProduct(result);
      }
      else {
        return <Redirect to={{pathname: '/products'}} push={true}/>
      }
    });
    setSimilarProducts([]);
    getSimilarProducts(id).then((data) => {
      setSimilarProducts(data);
    });
  }, [id]);

  if (!product) {
    return (
      <div sx={{ background: "white" }}>loading</div>
    );
  }
  return (
    <>
      <ProductDetailsPageHeader product={product} />
      <ProductDetailsPageDescriptionSection product={product} />
      <ProductDetailsPageTechnicalSection product={product} />
      {/*<ProductDetailsPagePhotoSection product={product} />*/}
      <ProductDetailsPageOtherProductsSection similarProducts={similarProducts} />
    </>
  );
};

export default ProductDetails;
