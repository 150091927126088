/** @jsxImportSource theme-ui */
import React from 'react'
import {ThemeUIStyleObject} from '@theme-ui/css'


const MorningIcon = (props: { sx?: ThemeUIStyleObject }) =>
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="28.074" height="15.668" viewBox="0 0 28.074 15.668" fill="#434343">
    <g id="mo49Xh.tif" transform="translate(-120.511 -517.82)">
      <g id="Group_139" data-name="Group 139" transform="translate(120.511 517.82)">
        <path id="Path_139" data-name="Path 139"
              d="M120.511,583.074c.694,0,1.389.011,2.084.011q2.441,0,4.882,0h.291c-.725-3.509.272-6.34,3.419-8.133a6.641,6.641,0,0,1,7.4.388,7,7,0,0,1,2.732,7.735h7.269v1.676H120.893c-.127,0-.254.013-.382.019Zm14.049.012c1.581,0,3.162,0,4.742,0a.291.291,0,0,0,.335-.228,5.3,5.3,0,0,0,.173-2.936,5.381,5.381,0,1,0-10.351,2.89.342.342,0,0,0,.386.277C131.416,583.08,132.988,583.086,134.56,583.086Z"
              transform="translate(-120.511 -569.104)"/>
        <path id="Path_140" data-name="Path 140" d="M273.658,517.82v3.307h-1.646V517.82Z"
              transform="translate(-258.789 -517.82)"/>
        <path id="Path_141" data-name="Path 141" d="M204.046,532.6l1.433-.825,1.652,2.849-1.432.83Z"
              transform="translate(-196.755 -530.552)"/>
        <path id="Path_142" data-name="Path 142" d="M324.385,535.462l-1.431-.826,1.651-2.852,1.432.826Z"
              transform="translate(-305.285 -530.565)"/>
        <path id="Path_143" data-name="Path 143" d="M161,580.15l-.824,1.432-2.859-1.646.826-1.429Z"
              transform="translate(-154.109 -573.209)"/>
        <path id="Path_144" data-name="Path 144" d="M366.479,579.936l-2.859,1.645-.825-1.431,2.857-1.644Z"
              transform="translate(-341.649 -573.209)"/>
        <path id="Path_145" data-name="Path 145" d="M143.923,647.605v-1.611h3.257v1.611Z"
              transform="translate(-141.88 -634.807)"/>
        <path id="Path_146" data-name="Path 146" d="M380.929,647.626V646.02H384.2v1.606Z"
              transform="translate(-358.2 -634.831)"/>
      </g>
    </g>
  </svg>
;

export default MorningIcon