/** @jsxImportSource theme-ui */
import React, {useState} from "react";
import {Container, Flex} from "theme-ui";
import {ThemeUIStyleObject} from '@theme-ui/css'

const ProductsEnergyManagement: React.FC = () => {
  const [selectedFlow, setSelectedFlow] = useState<"data" | "energy">("data");
  return (
    <Container sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      pb: '30px'
    }}>
      <h1 sx={{marginTop: 30, fontWeight: "bold", fontSize: "34px"}}>
        Gebäude-Energiemanagement
      </h1>
      <p sx={{
        marginTop: 25,
        maxWidth: 539,
        fontSize: 15,
        lineHeight: "30px",
        textAlign: "center",
        fontWeight: "normal",
      }}>
        Der von den PV-Modulen erzeugte Strom wird durch das 
        Energiemanagementsystem optimal auf die Wärmepumpe, die
        Wallbox und die Haushaltsgeräte verteilt. Überschüssiger Strom 
        wird im Energiespeicher gespeichert oder ins Netz eingespeist.
      </p>
      <Flex sx={{
        flexDirection: "row",
        minHeight: 100,
        minWidth: 240,
        marginTop: 20,
      }}>
        <FlowButton
          onClick={() => {
            setSelectedFlow("data");
          }}
          text="Datenfluss"
          active={selectedFlow === "data"}
          customSX={{
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20,
          }}
        />
        <FlowButton
          onClick={() => {
            setSelectedFlow("energy");
          }}
          text="Energiefluss"
          active={selectedFlow === "energy"}
          customSX={{
            borderTopRightRadius: 20,
            borderBottomRightRadius: 20,
          }}
        />
      </Flex>
      <Flex>
        {selectedFlow === "energy" && (
          <img
            src="/images/energyflow.png"
            sx={{maxWidth: "1076px"}}
            alt="energy-flow"
          />
        )}
        {selectedFlow === "data" && (
          <img
            src="/images/dataflow.png"
            sx={{maxWidth: "1076px"}}
            alt="data-flow"
          />
        )}
      </Flex>
    </Container>
  );
};

interface IFlowButtonProps {
  onClick: () => void;
  active: boolean;
  text: string;
  customSX: ThemeUIStyleObject
}

const FlowButton: React.FC<IFlowButtonProps> = ({onClick, active, text, customSX}) => {
  return (
    <Flex
      onClick={onClick}
      sx={{
        flex: 1,
        cursor: "pointer",
        userSelect: "none",
        background: active
          ? "linear-gradient(90deg, #169CD3 0%, #137198 50%, #5F49BA 100%)"
          : "#C6C6C6",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "bold",
        fontSize: 15,
        color: "white",
        maxHeight: 52,
        ...customSX
      }}
    >
      {text}
    </Flex>
  );
};
export default ProductsEnergyManagement;
