/** @jsxImportSource theme-ui */
import React from 'react'
import {ThemeUIStyleObject} from '@theme-ui/css'


const EnergyManagement = (props: { sx?: ThemeUIStyleObject }) =>
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="43.919" height="39.299" viewBox="0 0 43.919 39.299" fill="#169cd3">
    <g id="IoLHL5.tif" transform="translate(-46.354 -315.593)">
      <g id="Group_171" data-name="Group 171" transform="translate(46.354 315.593)">
        <path id="Path_912" data-name="Path 912"
              d="M46.354,348.278v-30.1a.641.641,0,0,0,.083-.139,3.158,3.158,0,0,1,3.251-2.442q18.632,0,37.264,0a3.16,3.16,0,0,1,3.32,3.347q0,14.319,0,28.638a3.178,3.178,0,0,1-3.361,3.354q-5.789,0-11.578,0h-.572c.775.715,1.452,1.361,2.157,1.976a1.073,1.073,0,0,0,.632.249c1.186.024,2.373.009,3.559.012.616,0,1.009.342,1.01.862s-.373.856-1.007.856q-12.8,0-25.6,0c-.632,0-1.006-.33-1-.86s.395-.856,1.014-.857c1.2,0,2.4,0,3.6,0a.658.658,0,0,0,.4-.083c.762-.679,1.508-1.375,2.348-2.149h-.579q-5.853,0-11.706,0a3.2,3.2,0,0,1-3.115-2.293C46.435,348.519,46.393,348.4,46.354,348.278Zm42.206-5.421V342.4q0-11.683,0-23.367a1.527,1.527,0,0,0-1.749-1.721H49.844c-1.226,0-1.776.551-1.776,1.78q0,11.641,0,23.281v.477H50.13v-.531q0-10.869,0-21.738c0-.143-.007-.286,0-.429a.82.82,0,0,1,.769-.778c.156-.014.314-.009.471-.009H85.252c.129,0,.258,0,.386,0a.82.82,0,0,1,.857.868q.008,4.845,0,9.69a.822.822,0,0,1-.558.83.856.856,0,0,1-1.154-.906q0-4.137,0-8.275v-.462H51.873v21.727H84.786c0-1.691,0-3.348,0-5.006,0-.623.332-.989.875-.979.522.01.834.37.836.976q0,2.08,0,4.159v.864ZM48.108,344.6a.631.631,0,0,0-.035.12q0,1.307-.006,2.615c0,1.368.52,1.886,1.893,1.886h36.7c.143,0,.286,0,.429,0a1.5,1.5,0,0,0,1.466-1.493c.013-.357,0-.714,0-1.072,0-.68,0-1.36,0-2.053ZM74.6,353.146c-.8-.739-1.527-1.421-2.27-2.088a.622.622,0,0,0-.386-.117q-3.62-.01-7.239,0a.583.583,0,0,0-.358.08c-.759.682-1.506,1.38-2.315,2.127Z"
              transform="translate(-46.354 -315.593)"/>
        <path id="Path_913" data-name="Path 913"
              d="M239.8,413.547h-2.569c-.172,0-.343,0-.515,0a.887.887,0,0,1-.8-1.445q2.3-3.935,4.618-7.864c.267-.455.531-.913.8-1.366a.892.892,0,0,1,1.067-.507.927.927,0,0,1,.621.972c0,1.7,0,3.4,0,5.1v.5c.17.009.307.023.445.023.872,0,1.744,0,2.616,0a.884.884,0,0,1,.832,1.424q-2.715,4.638-5.442,9.269a.885.885,0,0,1-1.033.5.913.913,0,0,1-.644-.954c-.005-1.715,0-3.43,0-5.146Zm-1.7-1.751c.828,0,1.568,0,2.308,0,.826,0,1.132.31,1.136,1.144,0,.886,0,1.772,0,2.658,0,.112.023.224.047.445l3.131-5.336c-.841,0-1.595.006-2.348,0-.782-.008-1.09-.317-1.1-1.1-.006-.886,0-1.772,0-2.658,0-.125-.019-.251-.04-.5Z"
              transform="translate(-219.451 -394.869)"/>
        <path id="Path_914" data-name="Path 914"
              d="M494.371,518.066a.839.839,0,0,1,.849.87.855.855,0,1,1-1.71-.012A.839.839,0,0,1,494.371,518.066Z"
              transform="translate(-455.077 -500.664)"/>
        <path id="Path_915" data-name="Path 915"
              d="M284.413,670.515c-.271,0-.543.014-.813,0a.836.836,0,0,1-.81-.874.8.8,0,0,1,.8-.83c.556-.02,1.113-.019,1.669,0a.806.806,0,0,1,.81.82.834.834,0,0,1-.8.884C284.985,670.531,284.7,670.515,284.413,670.515Z"
              transform="translate(-262.469 -638.436)"/>
      </g>
    </g>
  </svg>
;


export default EnergyManagement