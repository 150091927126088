/** @jsxImportSource theme-ui */
import React from 'react'


const FooterLogo = () =>
  <svg xmlns="http://www.w3.org/2000/svg" width="140.999" height="29.726" viewBox="0 0 140.999 29.726">
    <g id="Group_699" data-name="Group 699" transform="translate(-92.001 -54.274)">
      <text id="prosuCo" transform="translate(123 77)" fill="#fff" fontSize="29" fontFamily="Helvetica">
        <tspan x="0" y="0">prosuCo</tspan>
      </text>
      <path id="Exclusion_1" data-name="Exclusion 1"
            d="M21.432,29.189H2a2,2,0,0,1-2-2v-14.9a1.982,1.982,0,0,1,.372-1.161.762.762,0,0,1,.166-.252L11.186.221a.755.755,0,0,1,1.068,0L22.9,10.872a.766.766,0,0,1,.176.274,1.988,1.988,0,0,1,.355,1.138v14.9A2,2,0,0,1,21.432,29.189ZM12.74,5.513,4.842,17.72h8.741L8.069,25.675l2.206,1.455,8.483-12.123H10.032l5.2-7.844Z"
            transform="translate(92 54.275)" fill="#fff"/>
    </g>
  </svg>


;


export default FooterLogo