/** @jsxImportSource theme-ui */
import React from "react";
import {Container, Flex} from 'theme-ui'
import BreakCrumbs from "../../ui-kit/BreakCrumbs";
import {IProduct} from "../../rest/products";


const ProductDetailsPageHeader: React.FC<{ product: IProduct }> = (props: { product: IProduct }) => {
  return (
    <Flex sx={{
      position: 'relative',
      height: 362,
      backgroundColor: '#383838',
      zIndex: 0,
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        background: 'url(/products/product-details-page-bg.png) no-repeat center',
        backgroundSize: 'cover',
        filter: 'opacity(40%)',
        zIndex: -1
      }
    }}>
      <Container sx={{
        pt: '145px'
      }}>
        <BreakCrumbs
          items={[
            {name: "Home", url: "/"},
            {name: "Products", url: "/products"},
            {name: props.product.name, url: "/products/" + props.product.id},
          ]}
        />
      </Container>
    </Flex>
  );
};

export default ProductDetailsPageHeader;
