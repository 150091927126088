/** @jsxImportSource theme-ui */
import React from 'react'


const ArrowRight = () =>
  <svg xmlns="http://www.w3.org/2000/svg" width="21.359" height="14.145" viewBox="0 0 21.359 14.145">
    <g id="Icon_feather-arrow-right" data-name="Icon feather-arrow-right" transform="translate(-7 -6.793)">
      <path id="Path_884" data-name="Path 884" d="M7.5,18H27.859" transform="translate(0 -4.135)" fill="none"
            stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
      <path id="Path_885" data-name="Path 885" d="M18,7.5l6.365,6.365L18,20.23" transform="translate(3.494)" fill="none"
            stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
    </g>
  </svg>
;


export default ArrowRight