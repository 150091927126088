/** @jsxImportSource theme-ui */
import React from 'react'

const Logo = () =>
  <svg xmlns="http://www.w3.org/2000/svg" width="116" height="24.668" viewBox="0 0 116 24.668">
    <g id="Group_232" data-name="Group 232" transform="translate(-92 -52.332)">
      <text id="prosuCo" transform="translate(117 71)" fill="#fff" fontSize="24" fontFamily="Helvetica">
        <tspan x="0" y="0">prosuCo</tspan>
      </text>
      <path id="Exclusion_1" data-name="Exclusion 1"
            d="M-13136,1428.669h-15a2,2,0,0,1-2-2v-11.329a2,2,0,0,1,.265-.994.61.61,0,0,1,.172-.528l8.635-8.639a.611.611,0,0,1,.433-.178.611.611,0,0,1,.433.178l8.635,8.639a.614.614,0,0,1,.169.538,1.99,1.99,0,0,1,.261.984v11.329A2,2,0,0,1-13136,1428.669Zm-5.987-9.3-4.47,6.45,1.789,1.182,6.879-9.833h-7.077l4.216-6.358-2.021-1.34h0l-6.4,9.9Z"
            transform="translate(13245 -1352.669)" fill="#fff"/>
    </g>
  </svg>
;


export default Logo