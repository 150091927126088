/** @jsxImportSource theme-ui */
import React from "react";
import ProductsEnergyManagement from "src/components/products-page/ProductsEnergyManagement";
import ProductsHeader from "src/components/products-page/ProductsHeader";
import ProductsList from "src/components/products-page/ProductsList";
import { Flex } from "theme-ui";

const Products: React.FC = () => {
  return (
    <>
      <ProductsHeader />
      <Flex
        sx={{
          background: "white",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ProductsEnergyManagement />
        <ProductsList />
      </Flex>
    </>
  );
};

export default Products;
