/** @jsxImportSource theme-ui */
import React, {RefObject} from "react";
import {Flex} from 'theme-ui'
import ScrollDownIcon from "../../assets/icons/ScrollDownIcon";


const HomePageHeader: React.FC<{ elementRef: RefObject<HTMLInputElement> }> = ({elementRef}) => {

  function scrollToNextSection() {
    if (elementRef && elementRef.current) {
      elementRef.current.scrollIntoView({behavior: 'smooth', block: 'start'})
    }
  }

  return (
    <Flex sx={{
      position: 'relative',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '100vh',
      backgroundColor: 'black',
      zIndex: 0,
      padding: '30px',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        background: 'url(/home-page/smart-home-home-page-bg.png) no-repeat center',
        backgroundSize: 'cover',
        filter: 'opacity(40%)',
        zIndex: -1
      }
    }}>
      <Flex/>
      <Flex sx={{
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <h1 sx={{
          fontSize: '54px',
          color: 'white',
          maxWidth: '660px',
          textAlign: 'center',
          marginBottom: '30px'
        }}>{"Wir bringen Nachhaltigkeit zu Ihnen nach Hause"}</h1>
        <span sx={{
          color: 'white',
          fontSize: 2,
          marginBottom: '30px'
        }}>{"Erstellen Sie jetzt mit dem E-Consultant die beste Energielösung für Ihr Haus."}</span>
        <button sx={{
          variant: 'buttons.colorful',
          background: (t) => t?.colors?.gradientColor,
          fontFamily: 'body',
          lineHeight: 'body',
          fontWeight: 'bold',
          fontSize: 2
        }}>{"Los geht´s mit dem E-Consultant"}</button>
      </Flex>

      <Flex sx={{
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer'
      }} onClick={() => scrollToNextSection()}>
        <button sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          bg: 'transparent',
          border: '2px solid rgba(255, 255, 255, 0.1)',
          borderRadius: '38px',
          paddingY: '9px',
          paddingX: '7px'
        }}>
          <ScrollDownIcon/>
        </button>
        <span sx={{
          opacity: '0.6',
          marginLeft: '10px',
          fontSize: 2,
          fontWeight: 'heading',
          color: 'rgba(255, 255, 255, 0.6)'
        }}>{"Scroll down"}</span>
      </Flex>
    </Flex>
  );
};

export default HomePageHeader;
