/** @jsxImportSource theme-ui */
import React from 'react'
import {ThemeUIStyleObject} from '@theme-ui/css'


const DayIcon = (props: { sx?: ThemeUIStyleObject, mode?: string }) =>
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24.077" height="23.893" viewBox="0 0 24.077 23.893"
       fill="#434343" stroke="#434343">
    <g id="Group_140" data-name="Group 140" transform="translate(-1.923 0.5)">
      <path id="Path_140" data-name="Path 140" d="M273.6,517.82v3.186h-1.586V517.82Z"
            transform="translate(-258.819 -517.82)" strokeWidth="1"/>
      <path id="Path_150" data-name="Path 150" d="M273.6,521.006V517.82h-1.586v3.186Z"
            transform="translate(-258.819 -498.113)" strokeWidth="1"/>
      <path id="Path_141" data-name="Path 141" d="M204.046,532.565l1.38-.8,1.591,2.745-1.38.8Z"
            transform="translate(-196.568 -530.597)" strokeWidth="1"/>
      <path id="Path_151" data-name="Path 151" d="M204.046,534.519l1.38.8,1.591-2.745-1.38-.8Z"
            transform="translate(-196.567 -513.594)" strokeWidth="1"/>
      <path id="Path_142" data-name="Path 142" d="M324.333,535.327l-1.379-.8,1.59-2.748,1.38.8Z"
            transform="translate(-305.477 -530.61)" strokeWidth="1"/>
      <path id="Path_149" data-name="Path 149" d="M324.333,531.784l-1.379.8,1.591,2.748,1.38-.8Z"
            transform="translate(-305.477 -513.608)" strokeWidth="1"/>
      <path id="Path_143" data-name="Path 143" d="M160.87,580.089l-.794,1.379-2.754-1.586.8-1.377Z"
            transform="translate(-153.773 -573.403)" strokeWidth="1"/>
      <path id="Path_152" data-name="Path 152" d="M160.87,579.885l-.794-1.379-2.754,1.586.8,1.377Z"
            transform="translate(-153.773 -563.678)" strokeWidth="1"/>
      <path id="Path_144" data-name="Path 144" d="M366.345,579.883l-2.754,1.585-.794-1.379,2.752-1.583Z"
            transform="translate(-341.969 -573.403)" strokeWidth="1"/>
      <path id="Path_148" data-name="Path 148" d="M366.345,580.091l-2.754-1.585-.794,1.379,2.752,1.584Z"
            transform="translate(-341.969 -563.677)" strokeWidth="1"/>
      <path id="Path_145" data-name="Path 145" d="M143.923,647.546v-1.552h3.138v1.552Z"
            transform="translate(-141.5 -635.216)" strokeWidth="1"/>
      <path id="Path_146" data-name="Path 146" d="M380.929,647.567V646.02h3.148v1.547Z"
            transform="translate(-358.577 -635.24)" strokeWidth="1"/>
      <g id="Ellipse_17" data-name="Ellipse 17" transform="translate(7.198 4.703)" fill="none"
         stroke={props.mode === 'day' ? "#fff" : "#434343"}
         strokeWidth="2">
        <circle cx="6.785" cy="6.785" r="6.785" stroke="none"/>
        <circle cx="6.785" cy="6.785" r="5.785" fill="none"/>
      </g>
    </g>
  </svg>
;

export default DayIcon