/** @jsxImportSource theme-ui */
import React, {useEffect, useState} from "react";
import {Container, Flex} from 'theme-ui'
import {IProduct} from "../../rest/products";
import ThunderIcon from "../../assets/icons/ThunderIcon";


const ProductDetailsPageDescriptionSection: React.FC<{ product: IProduct }> = (props: { product: IProduct }) => {
  const [selectedPicture, setSelectedPicture] = useState<string>(props.product.image);
  useEffect(() => {
    setSelectedPicture(props.product.image)
  }, [props.product]);

  return (
    <Container sx={{
      display: 'grid',
      gridTemplateColumns: 'repeat(12, [col] 1fr)',
      gridColumnGap: '16px',
      paddingY: '40px'
    }}>
      <Flex sx={{
        gridColumn: 'col 2 / span 7',
        gridRow: 'row',
        flexDirection: 'column'
      }}>
        <h2 sx={{
          fontSize: 6,
          fontWeight: 'bold',
          marginBottom: '35px'
        }}>{props.product.name}</h2>
        <p sx={{
          fontSize: 15,
          lineHeight: 'text',
          mb: '30px'
        }}>
          {props.product.tagLine}
        </p>
        <Flex sx={{
          flexDirection: 'column',
          mb: '14px'
        }}>
          {props.product.advantages.map((record, key) => (
            <Flex key={key} sx={{
              flexDirection: 'row',
              alignItems: 'center',
              mb: '16px'
            }}>
              <Flex sx={{mr: '12px'}}>
                <ThunderIcon/>
              </Flex>
              <p>{record}</p>
            </Flex>
          ))}
        </Flex>
        <p sx={{
          fontSize: "15px",
          lineHeight: 'text'
        }}>
          {props.product.longDescription}
        </p>
      </Flex>
      <Flex sx={{
        position: 'relative',
        gridColumn: 'col 9 / span 4',
        gridRow: 'row',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <img src={selectedPicture} alt="product" sx={{
          objectFit: 'contain',
          flex: 1,
          maxWidth: '100%',
          maxHeight: '333px',
        }}/>
        <Flex sx={{
          width: '100%',
          position: 'relative',
          flexDirection: 'row',
          mt: '32px',
          overflowX: 'auto'
        }}>
          <img src={props.product.image} alt="product-extra" sx={{
            objectFit: 'contain',
            width: '100px',
            height: '50px',
            borderRadius: '10px',
            mr: '8px'
          }} onClick={() => setSelectedPicture(props.product.image)}/>
          {props.product.images.map((image, key) => (
            <img key={key} src={image} alt="product-extra" sx={{
              objectFit: 'cover',
              width: '100px',
              height: '50px',
              borderRadius: '10px',
              mr: '8px'
            }} onClick={() => setSelectedPicture(image)}/>
          ))}
        </Flex>
      </Flex>
    </Container>
  );
};

export default ProductDetailsPageDescriptionSection;
