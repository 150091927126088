/** @jsxImportSource theme-ui */
import React, {RefObject} from "react";
import {Container, Flex} from 'theme-ui'
import HomePageBenefitSectionIcon1
  from "../../assets/icons/home-page-benefit-section-icons/HomePageBenefitSectionIcon1";
import HomePageBenefitSectionIcon2
  from "../../assets/icons/home-page-benefit-section-icons/HomePageBenefitSectionIcon2";
import HomePageBenefitSectionIcon3
  from "../../assets/icons/home-page-benefit-section-icons/HomePageBenefitSectionIcon3";
import HomePageBenefitSectionIcon4
  from "../../assets/icons/home-page-benefit-section-icons/HomePageBenefitSectionIcon4";

type homePageBenefitSectionItemsType = {
  icon: React.ElementType,
  content: string
}
const homePageBenefitSectionItems: homePageBenefitSectionItemsType[] = [
  {
    icon: HomePageBenefitSectionIcon1,
    content: 'Sichern Sie sich vor steigenden Kosten fossiler Energie ab'
  },
  {
    icon: HomePageBenefitSectionIcon3,
    content: 'Kontrollieren Sie Ihre Energieerzeugung und -speicherung'
  },
  {
    icon: HomePageBenefitSectionIcon2,
    content: 'Profitieren Sie von langfristigen Einsparungen'
  },
  {
    icon: HomePageBenefitSectionIcon4,
    content: 'Verkaufen Sie überschüssige Energie am Strommarkt und generieren Sie zusäztliches Einkommen'
  }
];


const HomePageBenefitSection: React.FC<{ elementRef: RefObject<HTMLInputElement> }> = ({elementRef}) => {
  return (
    <Container ref={elementRef} sx={{
      display: 'grid',
      gridTemplateColumns: 'repeat(12, [col] 1fr)',
      gridColumnGap: '16px',
      paddingTop: '87px',
      marginBottom: '-51px',
      zIndex: 1
    }}>
      <Flex sx={{
        gridColumn: 'col 2 / span 11',
        gridRow: 'row',
        flexDirection: 'column'
      }}>
          <span sx={{
            fontSize: '11px',
            fontWeight: 'bold',
            color: 'secondary',
            marginBottom: '5px'
          }}>{"BENEFITS"}</span>
        <h2 sx={{
          fontSize: 6,
          fontWeight: 'bold',
          marginBottom: '39px'
        }}>{"Warum unsere Energielösung?"}</h2>
        <Flex sx={{
          flexDirection: 'row',
          alignItems: 'flex-start'
        }}>
          <img sx={{
            borderRadius: '10px',
            width: '550px',
            height: '300px',
            objectFit: 'cover',
            marginRight: '60px',
            objectPosition: '0 -35px'
          }} src="/home-page/home-page-benefit-section-image.png" alt="benefit section"/>
          <Flex sx={{
            flex: 1,
            flexDirection: 'row',
            alignItems: 'flex-start',
            flexWrap: 'wrap',
            marginTop: '10px'
          }}>
            {homePageBenefitSectionItems.map((singleItem: homePageBenefitSectionItemsType, index: number) =>
              <Flex key={index} sx={{
                width: 'calc(50% - 16px)',
                marginBottom: '60px',
                marginRight: '16px'
              }}>
                <div sx={{
                  width: '55px'
                }}>
                <singleItem.icon/>
                </div>
                <Flex sx={{
                  flex: 1,
                  lineHeight: 'text'
                }}>{singleItem.content}</Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Container>
  );
};

export default HomePageBenefitSection;
