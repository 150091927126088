/** @jsxImportSource theme-ui */
import React from 'react'


const ThunderIcon = () =>
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="22.16" viewBox="0 0 12 22.16">
    <path id="Path_929" data-name="Path 929" d="M19.5,16.5l-6,9h9l-6,9" transform="translate(-12 -14.42)" fill="none"
          stroke="#5f49ba" strokeLinecap="square" strokeLinejoin="round" strokeWidth="3"/>
  </svg>
;


export default ThunderIcon