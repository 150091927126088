/** @jsxImportSource theme-ui */
import React from 'react'


const ChevronRight = () =>
  <svg xmlns="http://www.w3.org/2000/svg" width="5.957" height="10.914" viewBox="0 0 5.957 10.914">
    <path id="Icon_feather-chevron-right" data-name="Icon feather-chevron-right" d="M13.5,18.5l4.75-4.75L13.5,9"
          transform="translate(-12.793 -8.293)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round"
          strokeWidth="1"/>
  </svg>
;


export default ChevronRight