/** @jsxImportSource theme-ui */
import React, {useEffect} from "react";
import {Box, Container, Flex} from 'theme-ui'
import {IProduct} from "../../rest/products";


interface ITechFieldProps {
  title: string;
  value: string;
}

const TechField: React.FC<ITechFieldProps> = ({title, value}) => {
  const textRef = React.createRef<HTMLElement>();

  useEffect(() => {
    if (textRef && textRef.current) {
      textRef.current.innerHTML = value;
    }
  }, [textRef, value]);

  return (
    <Flex sx={{
      minHeight: 70,
      flexDirection: "row",
      "&:not(:last-child)": {
        borderWidth: 0,
        borderBottomWidth: 2,
        borderColor: "#ccc",
        borderStyle: "dotted",
      },
    }}>
      <Flex sx={{
        width: '50%',
        justifyContent: "start",
        paddingLeft: 25,
        alignItems: "center",
        borderWidth: 0,
        borderRightWidth: 2,
        borderColor: "#ccc",
        borderStyle: "dotted",
        fontSize: "15px"
      }}>
        {title}
      </Flex>
      <Flex sx={{
        width: '50%',
        justifyContent: "start",
        paddingLeft: 36,
        alignItems: "center",
        fontSize: "15px"
      }} ref={textRef as React.RefObject<HTMLDivElement>} />
    </Flex>
  );
};

const ProductDetailsPageTechnicalSection: React.FC<{ product: IProduct }> = (props: { product: IProduct }) => {
  return (
    <Container sx={{
      display: 'grid',
      gridTemplateColumns: 'repeat(12, [col] 1fr)',
      gridColumnGap: '16px',
      paddingTop: '8px',
      paddingBottom: '46px'
    }}>
      <Flex sx={{
        gridColumn: 'col 2 / span 7',
        gridRow: 'row',
        flexDirection: 'column'
      }}>
        <h2 sx={{
          fontSize: "24px",
          fontWeight: "bold",
          mb: '38px'
        }}>
          Technische Daten
        </h2>
        <Flex sx={{
          boxShadow: "0px 0px 10px 5px #eee",
          borderRadius: 10,
          flexDirection: "column",
          mb: '23px'
        }}>
          {props.product.technicalData.map(({title, value}, key) => (
            <TechField title={title} value={value} key={key}/>
          ))}
        </Flex>
        <Flex sx={{
          flexDirection: "row",
          mb: '28px'
        }}>
          <button sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: 150,
            borderRadius: 10,
            background: "white",
            borderColor: "primary",
            borderWidth: 2,
            borderStyle: "solid",
            mr: '10px'
          }}>
            <img src={"/images/pdf.png"} alt="pdf" sx={{marginRight: "5px"}}/>
            <span sx={{
              color: "primary",
              fontSize: 15,
              fontWeight: "bold",
            }}>
              {"Data Sheet"}
            </span>
          </button>
          <button sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            variant: 'buttons.colorful',
            background: (t) => t?.colors?.gradientColor,
            fontFamily: 'body',
            lineHeight: 'body',
            fontWeight: 'bold',
            fontSize: 2,
          }}>{"E-Consultant"}</button>
        </Flex>
        <Flex sx={{flexDirection: "column", marginTop: "15px"}}>
          <span sx={{
            color: '#777777',
            mb: '4px'
          }}>{"Brand by:"}</span>
          <Box>
            <img src={props.product.brandIcon} alt="brand" sx={{maxHeight: 35, maxWidth: 100}}/>
          </Box>
        </Flex>

        {/*<Flex sx={{*/}
        {/*  flexDirection: "column",*/}
        {/*  margin: "40px 0px",*/}
        {/*}}>*/}
        {/*  <h1 sx={{fontSize: "24px", fontWeight: "bold"}}>More photos</h1>*/}
        {/*</Flex>*/}
        {/*<Flex sx={{*/}
        {/*  flexDirection: "row",*/}
        {/*}}>*/}
        {/*  {props.product.images.map((image, key) => (*/}
        {/*    <div key={key} sx={{flex: 1}}>*/}
        {/*      <img src={image} alt="product-extra" sx={{maxWidth: 425}}/>*/}
        {/*    </div>*/}
        {/*  ))}*/}
        {/*</Flex>*/}
      </Flex>
      {/*<Grid*/}
      {/*  gap="16px"*/}
      {/*  columns={[3, "1fr 10fr 1fr"]}*/}
      {/*  sx={{ background: "white" }}*/}
      {/*>*/}
      {/*  <Flex sx={{ flexDirection: "column", paddingTop: "50px" }}>*/}
      {/*    <Flex*/}
      {/*      sx={{*/}
      {/*        flexDirection: "column",*/}
      {/*        margin: "40px 0px",*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <h1 sx={{ fontSize: "24px", fontWeight: "bold" }}>More photos</h1>*/}
      {/*    </Flex>*/}
      {/*    <Flex*/}
      {/*      sx={{*/}
      {/*        flexDirection: "row",*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      {product.images.map((image, key) => (*/}
      {/*        <div key={key} sx={{ flex: 1 }}>*/}
      {/*          <img src={image} alt="product-extra" sx={{ maxWidth: 425 }} />*/}
      {/*        </div>*/}
      {/*      ))}*/}
      {/*    </Flex>*/}
      {/*  </Flex>*/}
      {/*</Grid>*/}
    </Container>
  );
};

export default ProductDetailsPageTechnicalSection;
