/** @jsxImportSource theme-ui */
import React from 'react'


const HomePageBenefitSectionIcon4 = () =>
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="41.42" height="35.6"
       viewBox="0 0 41.42 35.6">
    <defs>
      <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#169cd3"/>
        <stop offset="1" stopColor="#5f49ba"/>
      </linearGradient>
    </defs>
    <g id="up3c6w.tif" transform="translate(-64.345 -201.364)">
      <g id="Group_167" data-name="Group 167" transform="translate(64.345 201.364)">
        <path id="Path_896" data-name="Path 896"
              d="M64.345,214.712a2.984,2.984,0,0,1,1.259-.881q15.428-6.127,30.849-12.273l.188-.073a.877.877,0,0,1,1.335.582q1.815,4.552,3.625,9.105,2.014,5.059,4.027,10.117c.285.717.137,1.07-.581,1.359-1.509.607-3.017,1.217-4.533,1.805a.518.518,0,0,0-.4.577c.014,3.572.009,7.144.008,10.715a4.8,4.8,0,0,1-.013.485.77.77,0,0,1-.736.721c-.094.007-.189,0-.283,0q-16.847,0-33.693.007a1.018,1.018,0,0,1-1.053-.576Zm34.118,20.606V215.871H65.991v19.447ZM69.392,214.1l.023.092c1.52,0,3.041,0,4.561,0a.529.529,0,0,0,.245-.1,4.73,4.73,0,0,1,.666-.422q4.66-1.873,9.329-3.724,3.579-1.423,7.162-2.836a.866.866,0,0,1,1.081.27,4.755,4.755,0,0,0,4.062,1.764.91.91,0,0,1,1,.63c.543,1.375,1.1,2.745,1.66,4.113a.618.618,0,0,0,.254.325c.59.261.683.376.683,1.03q0,3.538,0,7.076v.541l3.765-1.505L96.675,203.24Zm8.763.02.026.049H97.526c-.41-1.028-.813-2.008-1.189-3a.5.5,0,0,0-.509-.394,6.259,6.259,0,0,1-4.03-1.735.5.5,0,0,0-.626-.119q-5.746,2.3-11.5,4.587C79.165,213.711,78.66,213.916,78.155,214.12Z"
              transform="translate(-64.345 -201.364)" fill="url(#linear-gradient)"/>
        <path id="Path_897" data-name="Path 897"
              d="M119.093,416.1q-4.935,0-9.87,0a.847.847,0,0,1-.963-.678,4.818,4.818,0,0,0-3.168-3.152.823.823,0,0,1-.654-.9q-.01-3.4,0-6.793a.828.828,0,0,1,.653-.9,4.815,4.815,0,0,0,3.168-3.151.82.82,0,0,1,.93-.663c.58-.011,1.159-.013,1.739-.012q9.02,0,18.04.012a.818.818,0,0,1,.923.672,4.808,4.808,0,0,0,3.133,3.133.833.833,0,0,1,.675.924c0,2.264,0,4.529,0,6.793a.841.841,0,0,1-.626.88,4.837,4.837,0,0,0-3.223,3.251.8.8,0,0,1-.85.6q-4.955-.008-9.91,0Zm-.016-14.632q-4.529,0-9.058,0c-.234,0-.387.029-.5.28a6.228,6.228,0,0,1-3.145,3.167.477.477,0,0,0-.311.523c.009,1.684.013,3.369,0,5.053a.534.534,0,0,0,.372.566,6.24,6.24,0,0,1,3.066,3.088.5.5,0,0,0,.547.338q9.038-.01,18.075,0a.475.475,0,0,0,.517-.319,6.252,6.252,0,0,1,3.167-3.146.421.421,0,0,0,.286-.457q-.013-2.587,0-5.174a.441.441,0,0,0-.316-.471,6.287,6.287,0,0,1-3.135-3.127.467.467,0,0,0-.5-.326C125.116,401.471,122.1,401.468,119.077,401.468Z"
              transform="translate(-101.184 -383.754)" fill="url(#linear-gradient)"/>
        <path id="Path_898" data-name="Path 898"
              d="M224.882,444.776a4.883,4.883,0,0,1,9.765-.015,4.883,4.883,0,1,1-9.765.015ZM229.754,448a3.24,3.24,0,1,0-.013-6.48,3.24,3.24,0,0,0,.013,6.48Z"
              transform="translate(-211.868 -420.554)" fill="url(#linear-gradient)"/>
        <path id="Path_899" data-name="Path 899"
              d="M160.7,484.9a1.231,1.231,0,1,1-1.234,1.24A1.266,1.266,0,0,1,160.7,484.9Zm-.082,1.678.173,0c.1-.146.283-.3.267-.435a.548.548,0,0,0-.349-.374.307.307,0,0,0-.379.38C160.362,486.3,160.52,486.435,160.62,486.578Z"
              transform="translate(-151.757 -461.916)" fill="url(#linear-gradient)"/>
        <path id="Path_900" data-name="Path 900"
              d="M382.7,486.115a1.228,1.228,0,1,1-1.255-1.215A1.255,1.255,0,0,1,382.7,486.115Zm-1.329.459.171,0c.105-.142.3-.29.291-.425a.549.549,0,0,0-.338-.386.309.309,0,0,0-.4.364C381.121,486.289,381.276,486.428,381.372,486.575Z"
              transform="translate(-354.637 -461.916)" fill="url(#linear-gradient)"/>
      </g>
    </g>
  </svg>
;


export default HomePageBenefitSectionIcon4