/** @jsxImportSource theme-ui */
import React from "react";
import BreakCrumbs from "src/ui-kit/BreakCrumbs";
import {Container, Flex} from "theme-ui";

const ProductsHeader: React.FC = () => {
  return (
    <Flex
      sx={{
        position: 'relative',
        height: 652,
        backgroundColor: '#383838',
        zIndex: 0,
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          width: '100%',
          height: '100%',
          background: 'url(/images/products-header.png) no-repeat center',
          backgroundSize: 'cover',
          filter: 'opacity(40%)',
          zIndex: -1
        }
      }}>
      <Container sx={{pt: 145}}>
        <BreakCrumbs
          items={[
            {name: "Home", url: "/"},
            {name: "Products", url: "/products"},
          ]}
        />
        <h1
          sx={{
            position: "absolute",
            fontSize: "42px",
            fontWeight: "normal",
            color: "white",
            maxWidth: 735,
            textAlign: "left",
          }}
        >
          Unsere Produkte sind auf dem neuesten Stand der Technik, sie
          modernisieren Ihr Haus und bieten Ihnen grenzenlose Möglichkeiten
          der Effizienz bei gleichbleibendem Komfort und finanziellen Einsparungen.
        </h1>
      </Container>
    </Flex>
  );
};
export default ProductsHeader;
