/** @jsxImportSource theme-ui */
import React from 'react'
import { ThemeUIStyleObject } from '@theme-ui/css'


const ChevronDown = (props: {sx?: ThemeUIStyleObject}) =>
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="7.414" height="4.207" viewBox="0 0 7.414 4.207">
    <path id="Icon_feather-chevron-down" data-name="Icon feather-chevron-down" d="M9,13.5l3,3,3-3"
          transform="translate(-8.293 -12.793)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round"
          strokeWidth="1"/>
  </svg>;


export default ChevronDown