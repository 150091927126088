/** @jsxImportSource theme-ui */
import React from 'react'


const ScrollDownIcon = () =>
  <svg xmlns="http://www.w3.org/2000/svg" width="12.328" height="25" viewBox="0 0 12.328 25">
    <circle id="Ellipse_8" data-name="Ellipse 8" cx="2" cy="2" r="2" transform="translate(3.914)" fill="#fff"/>
    <circle id="Ellipse_9" data-name="Ellipse 9" cx="2" cy="2" r="2" transform="translate(3.914 9)" fill="#fff"/>
    <path id="Icon_feather-chevron-down" data-name="Icon feather-chevron-down" d="M9,13.5l4.75,4.75L18.5,13.5"
          transform="translate(-7.586 5.75)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round"
          strokeWidth="2"/>
  </svg>
;


export default ScrollDownIcon