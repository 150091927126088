/** @jsxImportSource theme-ui */
import React from 'react'
import {ThemeUIStyleObject} from '@theme-ui/css'


const VehicleCharging = (props: { sx?: ThemeUIStyleObject }) =>
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="39.84" height="39.84" viewBox="0 0 39.84 39.84"
       fill="#169cd3" stroke="#fff">
    <g id="AMnj3g.tif" transform="translate(-135.397 -196.012)">
      <g id="Group_169" data-name="Group 169" transform="translate(135.897 196.512)">
        <path id="Path_902" data-name="Path 902"
              d="M164.648,235.352H135.9v-3.044h3.036V231.8q0-15.3,0-30.6a4.531,4.531,0,0,1,4.686-4.68q6.655,0,13.311,0a4.532,4.532,0,0,1,4.681,4.685q0,4.115,0,8.229v.468a10.849,10.849,0,0,1,1.357.008,4.613,4.613,0,0,1,3.966,4.7c-.051,3.362-.014,6.725-.012,10.087a1.108,1.108,0,1,0,2.2.031q0-8.4.007-16.8a.381.381,0,0,0-.258-.4,4.082,4.082,0,0,1-2.475-3.92c-.011-2.237,0-4.475,0-6.712v-.351h3.044v2.981h2.283v-3h3.017v7.814a.522.522,0,0,0-.068.13,4.192,4.192,0,0,1-2.289,3.028.541.541,0,0,0-.214.408c-.015,1.656-.009,3.312-.01,4.968,0,4.045.01,8.09-.006,12.135a4.132,4.132,0,0,1-8.26-.287q0-5.024,0-10.049a1.666,1.666,0,0,0-2.247-1.709v19.385h3Zm-6.094-3.05V212.926H141.984V232.3Zm.027-22.452v-.367q0-4.094,0-8.189a1.57,1.57,0,0,0-1.762-1.749q-6.539,0-13.079,0a1.576,1.576,0,0,0-1.774,1.775q0,4.075,0,8.151c0,.122.011.244.018.379Zm10.849-7.252c0,.411-.018.779,0,1.144a1.153,1.153,0,0,0,1.087,1.111,1.111,1.111,0,0,0,1.147-.949,10.187,10.187,0,0,0,.011-1.306Z"
              transform="translate(-135.897 -196.512)" strokeWidth="1"/>
        <path id="Path_903" data-name="Path 903"
              d="M260.457,466.35c0-.974,0-1.871.005-2.768a1,1,0,0,1,.118-.391c.421-.9.849-1.8,1.3-2.748h-6.038l3.488-8.272h3.325l-2.264,5.221h6.188c-.055.14-.09.244-.137.344q-1.6,3.41-3.2,6.816a1.058,1.058,0,0,1-.4.424C262.078,465.434,261.3,465.87,260.457,466.35Z"
              transform="translate(-246.724 -432.739)" strokeWidth="1"/>
      </g>
    </g>
  </svg>
;


export default VehicleCharging