/** @jsxImportSource theme-ui */
import React, {useRef} from "react";
import HomePageHeader from "../components/home-page/HomePageHeader";
import HomePageBenefitSection from "../components/home-page/HomePageBenefitSection";
import HomePageSolutionSection from "../components/home-page/HomePageSolutionSection";
import HomePageSystemSection from "../components/home-page/HomePageSystemSection";
import HomePageServicesSection from "../components/home-page/HomePageServicesSection";


const HomePage: React.FC = () => {
  const elementRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <HomePageHeader elementRef={elementRef}/>
      <HomePageBenefitSection elementRef={elementRef}/>
      <HomePageSolutionSection/>
      <HomePageSystemSection/>
      <HomePageServicesSection/>
    </>
  );
};

export default HomePage;
