/** @jsxImportSource theme-ui */
import React from 'react'


const HomePageBenefitSectionIcon3 = () =>
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="31.191" height="39.904"
       viewBox="0 0 31.191 39.904">
    <defs>
      <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#169cd3"/>
        <stop offset="1" stopColor="#5f49ba"/>
      </linearGradient>
    </defs>
    <g id="pGde7A.tif" transform="translate(-195.006 -419.184)">
      <g id="GhP8ne.tif" transform="translate(115 218.356)">
        <g id="Group_165" data-name="Group 165" transform="translate(80.006 200.828)">
          <path id="Path_887" data-name="Path 887"
                d="M87.893,240.732a17.309,17.309,0,0,1-4.068-3.585,14.7,14.7,0,0,1-3.474-6.48,13.08,13.08,0,0,1,2.621-11.307,50.175,50.175,0,0,1,5.14-5.256,27.223,27.223,0,0,0,3.325-3.7,13.92,13.92,0,0,0,2.59-8.09,2.458,2.458,0,0,1,.07-.692,1.164,1.164,0,0,1,1.931-.456c.824.713,1.644,1.434,2.427,2.192a50.92,50.92,0,0,1,9.409,12.086,26.573,26.573,0,0,1,3.1,8.5,14.867,14.867,0,0,1-3.428,12.513,21.948,21.948,0,0,1-4.612,4.039c-.126.085-.258.16-.388.24h-.623c-.939-.53-1.081-1.014-.609-2.01.227-.479.448-.963.643-1.456a12.718,12.718,0,0,0,1.138-5.586,3.862,3.862,0,0,0-.9-2.315.59.59,0,0,0-.6-.222,14.7,14.7,0,0,1-5.067-.022,4.953,4.953,0,0,1-4.2-5.83c.062-.5.152-1,.229-1.493l-.088-.052c-.5.577-1.031,1.127-1.485,1.735-2.482,3.324-3.031,7.122-2.566,11.116.182,1.562.59,3.1.908,4.643a1.174,1.174,0,0,1-.582,1.351c-.075.044-.147.093-.221.14Zm-1.761-4.449.1-.079a23.241,23.241,0,0,1-.22-5.742,16.407,16.407,0,0,1,3.056-8.365,13.614,13.614,0,0,1,5.568-4.613,1.18,1.18,0,0,1,1.544,1.683c-.263.52-.537,1.037-.759,1.574a8.03,8.03,0,0,0-.766,3.811,2.061,2.061,0,0,0,1.191,1.833,7.679,7.679,0,0,0,1.885.542,10.792,10.792,0,0,0,3.793-.165,2.141,2.141,0,0,1,2.088.719,6.111,6.111,0,0,1,.636.734,7.485,7.485,0,0,1,1.119,5.466c-.079.652-.225,1.3-.34,1.943l.116.108a1.725,1.725,0,0,1,.191-.34,12.89,12.89,0,0,0,3.161-11.982,25.715,25.715,0,0,0-3.222-7.794,53.378,53.378,0,0,0-8.917-10.962.749.749,0,0,0-.135-.079,2.306,2.306,0,0,0-.067.25,15.882,15.882,0,0,1-3.087,7.3,43.2,43.2,0,0,1-4.555,4.846c-1.3,1.281-2.611,2.572-3.777,3.974a10.414,10.414,0,0,0-2.382,6.956,10.8,10.8,0,0,0,1.972,6.007C84.883,234.732,85.528,235.493,86.132,236.283Z"
                transform="translate(-80.006 -200.828)" fill="url(#linear-gradient)"/>
        </g>
      </g>
    </g>
  </svg>
;


export default HomePageBenefitSectionIcon3