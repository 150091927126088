/** @jsxImportSource theme-ui */
import React from "react";
import {Flex} from 'theme-ui'
import {Link} from "react-router-dom";
import ChevronRight from "../assets/icons/ChevronRight";

interface IProps {
  items: { name: string; url: string }[];
}

const BreakCrumbs: React.FC<IProps> = ({items}: IProps) => {
  return (
    <Flex sx={{
      flexDirection: 'row',
      alignItems: 'center'
    }}>
      {items.map(({name, url}, key: number) => (
        <Link to={url} key={key} sx={{
          cursor: "pointer",
          fontSize: "15px",
          fontWeight: "normal",
          lineHeight: "30px",
          color: key < items.length - 1 ? "#169CD3" : "white",
        }}>
          {key > 0 &&
            <span sx={{
              mx: '8px'
            }}>
              <ChevronRight/>
            </span>
          }
          {name}
        </Link>
      ))}
    </Flex>
  );
};

export default BreakCrumbs;