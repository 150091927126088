/** @jsxImportSource theme-ui */
import React from 'react'
import {ThemeUIStyleObject} from '@theme-ui/css'


const EnergyStorage = (props: { sx?: ThemeUIStyleObject }) =>
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="44.259" height="39.798" viewBox="0 0 44.259 39.798"
       fill="#169cd3" stroke="#fff">
    <g id="gm8CGL.tif" transform="translate(-135.639 -222.347)">
      <g id="Group_170" data-name="Group 170" transform="translate(135.889 222.597)">
        <path id="Path_904" data-name="Path 904"
              d="M138.72,372.029a2.966,2.966,0,0,1-1.158-.236,3.548,3.548,0,0,1-.7-.367,2.305,2.305,0,0,1-.973-1.948q-.006-6.494,0-12.987A2.5,2.5,0,0,1,138.317,354c1.594-.035,3.19-.014,4.785-.018h.55a12.2,12.2,0,0,1,5.208-8.753,11.8,11.8,0,0,1,7.052-2.181,11.926,11.926,0,0,1,8.121,3.15,12.159,12.159,0,0,1,3.984,7.784c1.156,0,2.279-.005,3.4,0a5.121,5.121,0,0,1,1.1.072,2.555,2.555,0,0,1,1.99,2.505c0,.184,0,.367,0,.619h.857c.541,0,1.082-.008,1.623,0a2.594,2.594,0,0,1,2.571,1.921,1.855,1.855,0,0,0,.085.193v7.435c-.02.019-.05.035-.058.059a2.571,2.571,0,0,1-2.8,1.991h-2.279c0,.262.005.474,0,.685a2.622,2.622,0,0,1-1.945,2.465c-.091.025-.178.066-.267.1Zm33.175-15.438h-33.4v12.785h33.4Zm-25.562-2.643h19.024a9.6,9.6,0,0,0-19.024,0Zm30.714,12.236v-6.37h-2.505v6.37Z"
              transform="translate(-135.889 -332.731)" strokeWidth="0.5"/>
        <path id="Path_905" data-name="Path 905"
              d="M353.6,225.811c0-.6-.005-1.2,0-1.795a1.34,1.34,0,0,1,1.288-1.419,1.323,1.323,0,0,1,1.315,1.391q.023,1.837,0,3.675a1.326,1.326,0,0,1-1.308,1.4,1.34,1.34,0,0,1-1.295-1.413C353.594,227.036,353.6,226.424,353.6,225.811Z"
              transform="translate(-334.955 -222.597)" strokeWidth="0.5"/>
        <path id="Path_906" data-name="Path 906"
              d="M454.7,260.512a4.609,4.609,0,0,1,.262-.706q.9-1.6,1.833-3.178a1.295,1.295,0,1,1,2.236,1.289c-.62,1.087-1.242,2.173-1.88,3.25a1.251,1.251,0,0,1-1.464.595A1.377,1.377,0,0,1,454.7,260.512Z"
              transform="translate(-427.397 -253.033)" strokeWidth="0.5"/>
        <path id="Path_907" data-name="Path 907"
              d="M530.875,351.142a1.318,1.318,0,0,1-1.338-.879,1.239,1.239,0,0,1,.506-1.462c1.157-.7,2.326-1.375,3.509-2.025a1.268,1.268,0,0,1,1.672.5,1.246,1.246,0,0,1-.377,1.7c-1.163.713-2.356,1.379-3.544,2.05A1.4,1.4,0,0,1,530.875,351.142Z"
              transform="translate(-495.769 -336.013)" strokeWidth="0.5"/>
        <path id="Path_908" data-name="Path 908"
              d="M234.111,260.446a1.334,1.334,0,0,1-.895,1.332,1.208,1.208,0,0,1-1.435-.465c-.715-1.179-1.41-2.371-2.068-3.583a1.236,1.236,0,0,1,.52-1.628,1.251,1.251,0,0,1,1.7.373c.713,1.164,1.378,2.357,2.051,3.544A1.542,1.542,0,0,1,234.111,260.446Z"
              transform="translate(-221.557 -253.066)" strokeWidth="0.5"/>
        <path id="Path_909" data-name="Path 909"
              d="M143.56,351.322a3.574,3.574,0,0,1-.648-.217c-1.115-.627-2.227-1.261-3.327-1.913a1.276,1.276,0,0,1-.483-1.776,1.3,1.3,0,0,1,1.785-.451c1.114.628,2.228,1.259,3.323,1.921a1.276,1.276,0,0,1,0,2.218A3.586,3.586,0,0,1,143.56,351.322Z"
              transform="translate(-138.66 -336.148)" strokeWidth="0.5"/>
        <path id="Path_910" data-name="Path 910"
              d="M441.557,536c0,.42.007.787,0,1.154a1.308,1.308,0,1,1-2.6-.03c0-.354,0-.709,0-1.124-.41,0-.79,0-1.169,0a1.3,1.3,0,0,1-1.4-1.288,1.318,1.318,0,0,1,1.4-1.292c.368,0,.737,0,1.167,0,0-.4,0-.782,0-1.16a1.3,1.3,0,1,1,2.6-.029c.013.38,0,.761,0,1.189.426,0,.806,0,1.187,0a1.326,1.326,0,0,1,1.387,1.269A1.309,1.309,0,0,1,442.749,536C442.369,536.005,441.988,536,441.557,536Z"
              transform="translate(-410.654 -504.454)" strokeWidth="0.5"/>
        <path id="Path_911" data-name="Path 911"
              d="M199.717,563.4c-.81,0-1.62.006-2.431,0a1.3,1.3,0,0,1-1.406-1.279,1.324,1.324,0,0,1,1.434-1.3q2.452,0,4.9,0a1.329,1.329,0,0,1,1.41,1.286,1.307,1.307,0,0,1-1.4,1.293C201.395,563.4,200.556,563.4,199.717,563.4Z"
              transform="translate(-190.743 -531.851)" strokeWidth="0.5"/>
      </g>
    </g>
  </svg>
;


export default EnergyStorage