import React from "react";
import { Switch, Route } from "react-router-dom";
import ProductDetails from "src/pages/ProductDetails";
import Products from "src/pages/Products";
import HomePage from "../pages/HomePage";

const Router = () => (
  <Switch>
    <Route path={"/home"} exact={true} component={HomePage} />
    <Route path={"/products"} exact={true} component={Products} />
    <Route path={"/products/:id"} exact={false} component={ProductDetails} />
    <Route path={"/"} exact={true} component={HomePage} />
  </Switch>
);

export default Router;
