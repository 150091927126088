/** @jsxImportSource theme-ui */
import React from 'react'


const HomePageBenefitSectionIcon2 = () =>
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38.884" height="47"
       viewBox="0 0 38.884 47">
    <defs>
      <linearGradient id="linear-gradient" x1="0.5" y1="-0.268" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#169cd3"/>
        <stop offset="1" stopColor="#5f49ba"/>
      </linearGradient>
    </defs>
    <g id="KmunfF.tif" transform="translate(-68.256 -200.767)">
      <g id="Group_166" data-name="Group 166" transform="translate(68.256 200.767)">
        <path id="Path_888" data-name="Path 888"
              d="M68.256,322.65c.084-.352.145-.712.255-1.056a4.564,4.564,0,0,1,4.479-3.2c1.162-.012,2.323,0,3.485,0,.148,0,.3-.013.523-.024a5.327,5.327,0,0,1-1.1-4.685,5.053,5.053,0,0,1,1.942-2.967,5.262,5.262,0,0,1,6.811.325,5.386,5.386,0,0,1,.453,7.352h.577q8.323,0,16.646,0a4.531,4.531,0,0,1,4.566,3.2,5.454,5.454,0,0,1,.236,1.575q.024,9.336.008,18.672a4.587,4.587,0,0,1-3.762,4.692.754.754,0,0,0-.161.078H72.2c-.1-.033-.195-.074-.3-.1a4.546,4.546,0,0,1-3.415-3.155c-.1-.314-.158-.645-.235-.968v-5.966a3.775,3.775,0,0,1,.212-.338.875.875,0,0,1,.925-.367.849.849,0,0,1,.7.7,3.364,3.364,0,0,1,.045.593c0,1.591.041,3.183-.008,4.772a2.867,2.867,0,0,0,3.007,3.009c8.364-.029,16.728-.013,25.093-.014a2.77,2.77,0,0,0,3.018-2.983c.008-1.407,0-2.815,0-4.222,0-.148-.016-.3-.027-.491h-.576c-3.073-.005-6.147.025-9.22-.027a4.525,4.525,0,0,1-4.266-4.211,4.572,4.572,0,0,1,3.909-4.864,9.559,9.559,0,0,1,1.142-.073q4.22-.009,8.441,0h.6v-.492c0-1.392-.027-2.785.006-4.176a2.861,2.861,0,0,0-3.006-3q-12.569.061-25.138,0a2.858,2.858,0,0,0-2.983,2.974c.049,1.651.049,13.656.043,15.308,0,.667-.273,1.04-.782,1.119-.49.076-.777-.12-1.1-.752C68.291,336.918,68.256,324.608,68.256,322.65Zm37,7.119H92.1a2.04,2.04,0,0,0-.638.028,5.232,5.232,0,0,0-1.448.605,2.6,2.6,0,0,0-.856,3,2.7,2.7,0,0,0,2.591,1.826q6.555.008,13.111,0c.13,0,.26-.02.393-.03Zm-24.227-11.4a3.436,3.436,0,0,0,3.475-3.4,3.449,3.449,0,1,0-6.9-.089A3.415,3.415,0,0,0,81.025,318.365Zm21.184,26.363a1.339,1.339,0,0,0,.186.042,2.741,2.741,0,0,0,2.871-2.828c.02-1.48.006-2.961,0-4.441a3.9,3.9,0,0,0-.039-.394h-2.123c0,1.185-.013,2.342,0,3.5A6.165,6.165,0,0,1,102.209,344.728Zm3.03-16.85c0-1.8.061-3.561-.019-5.312a2.56,2.56,0,0,0-2.928-2.261,5.7,5.7,0,0,1,.815,3.586v3.987Z"
              transform="translate(-68.256 -299.606)" fill="url(#linear-gradient)"/>
        <path id="Path_891" data-name="Path 891"
              d="M305.256,228.088a5.293,5.293,0,1,1-5.255-5.3A5.315,5.315,0,0,1,305.256,228.088Zm-5.342,3.435a3.451,3.451,0,0,0,.09-6.9,3.475,3.475,0,0,0-3.5,3.39A3.438,3.438,0,0,0,299.914,231.523Z"
              transform="translate(-273.848 -220.763)" fill="url(#linear-gradient)"/>
        <path id="Path_892" data-name="Path 892"
              d="M246.722,205.321c0,1.161.005,2.323,0,3.484a.905.905,0,0,1-.9.995.92.92,0,0,1-.931-1.019q-.007-3.507,0-7.014a.944.944,0,0,1,.728-.981.9.9,0,0,1,1.039.557,1.668,1.668,0,0,1,.066.539C246.725,203.029,246.722,204.175,246.722,205.321Z"
              transform="translate(-228.64 -200.767)" fill="url(#linear-gradient)"/>
        <path id="Path_893" data-name="Path 893"
              d="M197.027,203.743c0-.626-.007-1.253,0-1.879a.929.929,0,0,1,.944-1.009.893.893,0,0,1,.894,1q.015,1.925,0,3.85a.912.912,0,0,1-.93,1.016.957.957,0,0,1-.909-1.051C197.022,205.026,197.027,204.384,197.027,203.743Z"
              transform="translate(-185.182 -200.847)" fill="url(#linear-gradient)"/>
        <path id="Path_895" data-name="Path 895"
              d="M311.821,550.178a.924.924,0,0,1-.911-.9.968.968,0,0,1,.944-.934.922.922,0,0,1,.895.966A.9.9,0,0,1,311.821,550.178Z"
              transform="translate(-288.595 -516.375)" fill="url(#linear-gradient)"/>
      </g>
    </g>
  </svg>
;


export default HomePageBenefitSectionIcon2