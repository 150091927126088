/** @jsxImportSource theme-ui */
import React from 'react'
import {Container, Box, Input} from "theme-ui";
import ArrowRight from "../../assets/icons/ArrowRight";


const NewsLetter = () => {
  return (
    <div sx={{
      background: (t) => 'url(/newsletter-bg.png) no-repeat, ' + t?.colors?.crookedGradientColor,
      backgroundSize: 'cover',
      paddingTop: '71px',
      paddingBottom: '100px',
    }}>
      <Container sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <h2 sx={{
          color: 'white',
          fontSize: 6,
          fontWeight: 'bold',
          marginBottom: '29px'
        }}>{"Abonnieren Sie unseren Newsletter"}</h2>
        <p sx={{
          color: 'white',
          maxWidth: '536px',
          lineHeight: 'text',
          mb: '42px',
          textAlign: 'center'
        }}>{"Mit unserem Newsletter halten wir Sie über die neuesten Trends und Entwicklungen auf dem Laufenden."}</p>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row'
        }} as="form" onSubmit={(e) => e.preventDefault()}>
          <Input sx={{
            width: '394px',
            height: '50px',
            bg: 'white',
            borderRadius: '10px 0 0 10px',
            border: 'none',
            fontSize: '15px',
            padding: '15px'
          }} name="email" id="username" placeholder="Ihre Email-Adresse" mb={3} />
          <button sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            variant: 'buttons.colorful',
            background: (t) => t?.colors?.gradientColor,
            fontFamily: 'body',
            lineHeight: 'body',
            fontWeight: 'bold',
            fontSize: 2,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}>
            <span sx={{
              mr: '12px'
            }}>Subscribe</span>
            <ArrowRight/>
          </button>
        </Box>
      </Container>
    </div>
  )
};

export default NewsLetter