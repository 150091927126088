import Axios from "axios";

export interface IProduct {
  id: string;
  name: string;
  description: string;
  image: string;
  imageSize: {
    w: string;
    h: string;
    mr: string;
    mb: string;
  };
  brandIcon: string;
  images: string[];
  technicalData: { title: string; value: string }[];
  advantages: string[];
  tagLine: string;
  longDescription: string;
  mainPictureTop: number;
  productCardPictureWidth: number;
  productCardPictureBottom: number;
}
export const getProducts = async (): Promise<IProduct[]> => {
  return Axios.get<IProduct[]>("/products.json").then((data) => data.data);
};

export const getSimilarProducts = async (pid: string): Promise<IProduct[]> => {
  return getProducts().then((products) =>
    products.filter((record) => record.id !== pid)
  );
};
