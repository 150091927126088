/** @jsxImportSource theme-ui */
import React from "react";
import {Container, Flex} from 'theme-ui'
import MorningIcon from "../../assets/icons/home-page-solution-section-icons/MorningIcon";
import DayIcon from "../../assets/icons/home-page-solution-section-icons/DayIcon";
import NightIcon from "../../assets/icons/home-page-solution-section-icons/NightIcon";

type homePageSolutionSectionItemsType = {
  icon: React.ElementType,
  content: string,
  id: string
}
const homePageSolutionSectionItems: homePageSolutionSectionItemsType[] = [
  {
    icon: MorningIcon,
    content: ' Morgen',
    id: 'morning'
  },
  {
    icon: DayIcon,
    content: ' Tag',
    id: 'day'
  },
  {
    icon: NightIcon,
    content: ' Nacht',
    id: 'night'
  }
];
const HomePageSolutionSection: React.FC = () => {
  const [solutionMode, setSolutionMode] = React.useState<string>(homePageSolutionSectionItems[0].id);
  return (
    <div sx={{
      bg: 'grayBackground',
      overflow: 'hidden'
    }}>
      <Container sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(12, [col] 1fr)',
        gridColumnGap: '16px',
        paddingTop: '152px',
        paddingBottom: '92px'
      }}>
        <Flex sx={{
          gridColumn: 'col 2 / span 5',
          gridRow: 'row',
          flexDirection: 'column'
        }}>
          <h2 sx={{
            fontSize: 6,
            fontWeight: 'bold',
            marginBottom: '20px'
          }}>{"Wie funktioniert unsere Energielösung?"}</h2>
          <p sx={{
            lineHeight: 'text',
            mb: '40px'
          }}>{"Unsere Energielösung passt ihre Funktionen im Laufe des Tages automatisch an. " +
          "Wir zeigen Ihnen wie das geht und begleiten Sie auf einer virtuellen Reise."}</p>
          <Flex sx={{
            width: '442px',
            flexDirection: 'row',
            alignSelf: 'flex-start',
            alignItems: 'center',
            justifyContent: 'space-around',
            height: '52px',
            borderRadius: '26px',
            bg: '#E6E6E6',
            marginBottom: '20px',
          }}>
            {homePageSolutionSectionItems.map((singleItem: homePageSolutionSectionItemsType, index: number) =>
              <Flex key={index} sx={{
                position: 'relative',
                flexDirection: 'row',
                alignItems: 'center',
                height: '34px',
                borderRadius: '20px',
                paddingX: '20px',
                cursor: 'pointer',
                background: (t) => t?.colors?.gradientColor,
                color: (solutionMode === singleItem.id ? 'white' : 'text'),
                zIndex: 1,
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  bg: '#E6E6E6',
                  opacity: (solutionMode === singleItem.id ? '0' : '1'),
                  zIndex: -1,
                  transition: 'all 0.3s ease',
                }
              }} onClick={() => setSolutionMode(singleItem.id)}>
                <singleItem.icon sx={{
                  fill: (solutionMode === singleItem.id ? 'white' : '#434343'),
                  stroke: (solutionMode === singleItem.id && singleItem.id === 'day' ? 'white' : 'unset')
                }} mode={solutionMode}/>
                <span>{singleItem.content}</span>
              </Flex>
            )}
          </Flex>
          <p sx={{
            lineHeight: 'text'
          }}>{"Die Sonne scheint nicht mehr und die PV-Anlage produziert keinen Strom. " +
          "Der Stromverbrauch im Haus steigt, wenn der Besitzer von der Arbeit zurückkommt und die in der Batterie gespeicherte Energie " +
          "wird zum Aufladen des Elektrofahrzeugs und zur Deckung des Strombedarfs im Haus verwendet."}</p>
        </Flex>
        <Flex sx={{
          gridColumn: 'col 7 / span 6',
          gridRow: 'row',
        }}>
          <img sx={{
            marginLeft: '56px',
            objectFit: 'cover',
          }} src={"/home-page/home-page-solution-section-" + solutionMode.toLowerCase() + "-pic.png"} alt={solutionMode}/>
        </Flex>
      </Container>
    </div>
  );
};

export default HomePageSolutionSection;
